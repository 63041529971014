/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { useForm } from 'react-hook-form';
import Img from 'gatsby-image';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Hero from '../components/hero';
import FAQAccordion from '../components/faqaccordion';

import './pricing.scss';

const Pricing = ({
  pageContext: {
    metaDescription,
    metaTitle,
    subTextHtml,
    header,
    levels,
    benefits,
    addOns
  }
}) => {
  const convertToClass = input =>
    input.replace('&', 'and').replace(/ +/g, '-').toLowerCase();

  return (
    <Layout>
      <SEO title={metaTitle} description={metaDescription} />
      <section className="pricing--hero">
        <div className="container">
          <h1 className="pricing--hero--header">{header}</h1>
          {subTextHtml && (
            <div
              className="pricing--hero--description"
              dangerouslySetInnerHTML={{ __html: subTextHtml }}
            ></div>
          )}
        </div>
      </section>
      <section className="pricing--level--section">
        <div className="container">
          <div className="pricing--level--levels">
            {levels
              .sort((a, b) => a - b)
              .map(
                (
                  {
                    mostPopular,
                    commitment,
                    moneyBackGuarantee,
                    optimizationRate,
                    otherFee,
                    price,
                    priceTimeUnit,
                    priceUnit,
                    startingAt,
                    subtitle,
                    title,
                    id
                  },
                  i
                ) => (
                  <article
                    className={`pricing--level${
                      mostPopular ? ' pricing--level-most-popular' : ''
                    }`}
                    key={id}
                  >
                    <div className="pricing--level--head">
                      <h2 className="pricing--level--title">{title}</h2>
                      <p className="pricing--level--subtitle">{subtitle}</p>
                      {false && optimizationRate && (
                        <div>{optimizationRate}% optimization rate</div>
                      )}
                      <div className="pricing--level--price">
                        {startingAt && (
                          <div className="pricing--level--price--starting">
                            Starting at
                          </div>
                        )}
                        <span className="pricing--level--price--unit">
                          {priceUnit}
                        </span>
                        <span className="pricing--level--price--number">
                          {price}{' '}
                        </span>
                        {priceTimeUnit && (
                          <span className="pricing--level--price--time">
                            {priceTimeUnit}
                          </span>
                        )}
                        {commitment && (
                          <div className="pricing--level--price--commitment">
                            {commitment}
                          </div>
                        )}
                        {otherFee && (
                          <div className="pricing--level--price--fee">
                            {otherFee}
                          </div>
                        )}
                      </div>
                      {moneyBackGuarantee && (
                        <div className="pricing--level--money-back">
                          Money-Back Guarantee
                        </div>
                      )}
                    </div>
                    <div className="pricing--level--benefits">
                      {benefits
                        .filter(({ points }) =>
                          points.some(point => point.levels.includes(i))
                        )
                        .map(({ title, points }) => (
                          <article
                            key={title}
                            className={`pricing--level--benefits--benefit pricing--level--benefits--benefit-${convertToClass(
                              title
                            )}`}
                          >
                            <h3 className="pricing--level--benefits--benefit--header">
                              {title}
                            </h3>
                            <ul className="pricing--level--benefits--benefit--points">
                              {points
                                .filter(point => point.levels.includes(i))
                                .map(({ name }) => (
                                  <li
                                    key={name}
                                    className="pricing--level--benefits--benefit--points--point"
                                  >
                                    {name}
                                  </li>
                                ))}
                            </ul>
                          </article>
                        ))}
                    </div>
                  </article>
                )
              )}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Pricing;
